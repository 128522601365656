<template>
  <!-- Esto por ahora no se usa, ver GLP-23920 -->
  <v-card>
    <v-card-title
      class="title primary white--text"
    >
      <v-badge
        color="red"
        right
      >
        <template
          v-if="items.length > 0"
          #badge
        >
          {{ items.length }}
        </template>
        <v-icon
          dark
          left
        >
          {{ $vuetify.icons.values.operacionCRM }}
        </v-icon>
        Operaciones cumplidas
      </v-badge>
    </v-card-title>
    <v-list>
      <template
        v-for="(item, index) in visibleItems"
      >
        <v-list-item
          :key="'tile_' + index"
          :ripple="false"
          @click.stop="$emit('click-item-list', item)"
        >
          <v-list-item-avatar>
            <div
              class="text-xs-center"
            >
              <v-avatar
                :color="item.avatarColor"
              >
                <span class="white--text title">{{ item.avatar }}</span>
              </v-avatar>
            </div>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              class="text-wrap"
            >
              {{ item.title }}
            </v-list-item-title>
            <v-list-item-subtitle
              class="text-wrap"
            >
              {{ item.subtitle }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider
          v-if="index < items.length - 1 && index < DASHBOARD.cantidadElementos - 1"
          :key="index"
        />
      </template>
    </v-list>
    <v-card-actions
      v-if="items.length > 0"
    >
      <v-spacer />
      <v-btn
        color="primary"
        text
        small
        @click.stop="$emit('click-ver-todas')"
      >
        Ver todas
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { DASHBOARD } from '@/utils/consts'

export default {
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      DASHBOARD: DASHBOARD,
    }
  },
  computed: {
    visibleItems () {
      return this.items.slice(0, DASHBOARD.cantidadElementos)
    }
  },
}
</script>
